import '@doltech/ui/lib/antd/dol.theme.global.less';
import '@doltech/ui/lib/antd/landing.theme.global.less';

import { withAppScroll } from '@doltech/core/lib/appScroll/withAppScroll';
import { bootstrapAuthManager } from '@doltech/core/lib/auth/bootstrapAuthManager';
import { withGlobalUserAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { withEncryptedData } from '@doltech/core/lib/encrypt-page-data/withEncryptedData';
import { environment } from '@doltech/core/lib/environment/environment';
import { withGTM } from '@doltech/core/lib/hooks/gtm/withGTM';
import { withGTMPageContext } from '@doltech/core/lib/hooks/gtm/withGTMPageContext';
import { withReactQueryConfig } from '@doltech/core/lib/hooks/withReactQueryConfig';
import { withDolSystemDesignThemeProvider } from '@doltech/ui/lib/figma/Common/theme';
import { withLandingSharedLayout } from '@doltech/ui/lib/figma/Layout/Landing/hocs/withLandingSharedLayout';
import { useTrackingLastUrlAndAutoScrollToTop } from '@doltech/ui/lib/hocs/withDolSetup';
import { withGlobalModal } from '@doltech/ui/lib/hocs/withGlobalModal';
import { withUpdateViewCount } from '@doltech/ui/lib/hocs/withUpdateViewCount';
import { compose } from '@doltech/utils/lib/compose';
import { initNumeralConfig } from '@doltech/utils/lib/numeral-config';
import { AppProps } from 'next/app';
import * as React from 'react';
import { branch } from '@doltech/utils/lib/branch';
import { withGlobalModalPortal } from '@doltech/ui/lib/hocs/withGlobalModalPortal';
import { withUserAuthentication } from '@doltech/ui/lib/figma/Auth/hocs/withUserAuthentication';

initNumeralConfig();
bootstrapAuthManager('landing-grammar');

interface CustomAppProps extends Omit<AppProps, 'Component'> {
  err: any;
  Component: any;
}

function CustomApp({ Component, pageProps }: CustomAppProps) {
  useTrackingLastUrlAndAutoScrollToTop();
  return <Component {...pageProps} />;
}

export default compose(
  withEncryptedData,
  branch(
    () => environment.production,
    withGTM({
      gtmId: 'GTM-5H4WFFNB',
      env: ['PROD'],
    }),
    withGTM({
      gtmId: 'GTM-K7J89CZ',
      env: [''],
    })
  ),
  withReactQueryConfig,
  withDolSystemDesignThemeProvider,
  withAppScroll({
    getAppScrollElement: () => window,
    getAppContainer: () => document.getElementById('app-root'),
  }),
  withGlobalUserAuthInfo,
  withGlobalModal,
  withUserAuthentication({}, true),
  withGTMPageContext({ showMessengerButton: true }),
  withUpdateViewCount,
  withLandingSharedLayout,
  withGlobalModalPortal
)(CustomApp);
